import { Show, createSignal } from 'solid-js';
import { Button, Dialog, DialogContent, DialogTrigger, Link, Picture } from '@troon/ui';
import { Icon } from '@troon/icons';
import { useAction, useSubmission } from '@solidjs/router';
import { gql, mutationAction, useMutation } from '../../graphql';
import { createFragment } from '../../graphql/create-fragment';
import { useUser } from '../../providers/user';
import { TeeTimeAlertForm } from '../../partials/tee-time-alerts';
import { AuthFlow } from '../../partials/auth/auth';
import type { TeeTimeAlertFormFragmentDoc } from '../../graphql';
import type { FragmentType } from '../../graphql/test';

type Props = {
	facility: FragmentType<typeof FacilityHeaderButtonsFragment> & FragmentType<typeof TeeTimeAlertFormFragmentDoc>;
	useFacilityDialog?: boolean;
};

export function FacilityHeaderButtons(props: Props) {
	const facility = createFragment(FacilityHeaderButtonsFragment, props, 'facility');
	const user = useUser();
	const favoriteAction = useMutation(handleFavorite);
	const favorite = useAction(favoriteAction);
	const sub = useSubmission(favoriteAction, ([data]) => data.get('facilityId') === facility.id);
	const [teeTimeAlertOpen, setTeeTimeAlertOpen] = createSignal(false);

	function handleFavoriting() {
		const data = new FormData();
		data.set('facilityId', facility.id);
		data.set(
			'isFavorite',
			(sub.result?.data?.toggleFavoriteFacility.isFavorite ?? facility.isFavorite) ? 'false' : 'true',
		);
		favorite(data);
	}

	return (
		<>
			<Show
				when={user()}
				fallback={
					<Dialog key="favorite-facility-auth">
						<DialogTrigger class="size-fit grow-0" appearance="transparent-current">
							<Icon name="star" />
							<span class="sr-only">Log in to favorite {facility.name}</span>
						</DialogTrigger>
						<DialogContent width="lg" noPadding closeButton="text-white">
							<AuthFlow onComplete={handleFavoriting} />
						</DialogContent>
					</Dialog>
				}
			>
				<Button
					class="size-fit grow-0"
					appearance="transparent-current"
					disabled={sub.pending}
					onClick={handleFavoriting}
				>
					<Icon
						name={(sub.result?.data?.toggleFavoriteFacility.isFavorite ?? facility.isFavorite) ? 'star-filled' : 'star'}
						class={
							(sub.result?.data?.toggleFavoriteFacility.isFavorite ?? facility.isFavorite) ? 'text-brand' : undefined
						}
					/>
					<span class="sr-only">Toggle {facility.name} as a favorite</span>
				</Button>
			</Show>
			<Show when={facility.isBookable}>
				<Show
					when={user()}
					fallback={
						<Dialog key="tee-time-alert-auth">
							<DialogTrigger class="size-fit grow-0" appearance="transparent-current">
								<Icon name="bell-ring" />
								<span class="sr-only">Log in to set tee time alerts for {facility.name}</span>
							</DialogTrigger>
							<DialogContent width="lg" noPadding closeButton="text-white">
								<AuthFlow onComplete={() => setTeeTimeAlertOpen(true)} />
							</DialogContent>
						</Dialog>
					}
				>
					<Dialog key="tee-time-alert" open={teeTimeAlertOpen()} onOpenChange={setTeeTimeAlertOpen}>
						<DialogTrigger class="size-fit grow-0" appearance="transparent-current">
							<Icon name="bell-ring" />
							<span class="sr-only">Set tee time alerts for {facility.name}</span>
						</DialogTrigger>
						<DialogContent header="Create a Tee Time Alert" headerLevel="h3" height="fit">
							<TeeTimeAlertForm
								facilities={[facility]}
								onCancel={() => setTeeTimeAlertOpen(false)}
								onSaved={() => setTeeTimeAlertOpen(false)}
							/>
						</DialogContent>
					</Dialog>
				</Show>
			</Show>
			<Show
				when={props.useFacilityDialog}
				fallback={
					<Button class="size-fit grow-0" appearance="transparent-current" as={Link} href={`/course/${facility.slug}`}>
						<Icon name="info" />
						<span class="sr-only">View {facility.name}</span>
					</Button>
				}
			>
				<Dialog key="facility-info">
					<DialogTrigger class="size-fit grow-0" appearance="transparent-current">
						<Icon name="info" />
						<span class="sr-only">View {facility.name}</span>
					</DialogTrigger>
					<DialogContent header={facility.name} headerLevel="h3">
						<div class="flex flex-col gap-8">
							<div class="relative">
								<Picture
									src={facility.metadata?.hero?.url}
									width={600}
									height={300}
									sizes="(min-width: 768px) 36rem, 95vw"
									alt=""
									class="w-full overflow-hidden rounded"
								/>
								<Show when={facility.metadata?.logo?.url}>
									{(src) => (
										<div class="absolute bottom-6 left-6 z-10 aspect-square size-28 rounded border border-neutral bg-white p-2 shadow">
											<Picture
												preload
												src={src()}
												alt=""
												mode="contain"
												width={200}
												height={200}
												sizes="6rem"
												class="w-full"
											/>
										</div>
									)}
								</Show>
							</div>
							<p class="line-clamp-6">{facility.metadata?.description}</p>
							<div class="flex flex-col gap-4">
								<Button as={Link} href={`/course/${facility.slug}/reserve-tee-time`}>
									View all tee times
								</Button>
								<Button appearance="secondary" as={Link} href={`/course/${facility.slug}`}>
									Course detail
								</Button>
							</div>
						</div>
					</DialogContent>
				</Dialog>
			</Show>
		</>
	);
}

const FacilityHeaderButtonsFragment = gql(`fragment FacilityHeaderButtons on Facility {
	id
	name
	slug
	isBookable
	isFavorite
	metadata {
		description
		hero { url }
		logo { url }
	}
	...TeeTimeAlertForm
}`);

const toggleFavorite = gql(`mutation toggleFavoriteFacility($facilityId: String!, $isFavorite: Boolean!) {
	toggleFavoriteFacility(facilityId: $facilityId, isFavorite: $isFavorite) {
		name
		isFavorite
	}
}`);

const handleFavorite = mutationAction(toggleFavorite, {
	transform: (data) => ({
		facilityId: data.get('facilityId') as string,
		isFavorite: data.get('isFavorite') === 'true',
	}),
	toast: (data) =>
		data?.toggleFavoriteFacility.isFavorite
			? `Added ${data?.toggleFavoriteFacility.name} as a favorite`
			: `Removed ${data?.toggleFavoriteFacility.name} as a favorite.`,
	revalidates: ['facility', 'home'],
});
